import React from "react";
import { Link } from "gatsby";
import { IoIosInformationCircle, IoIosGlobe, IoIosEye } from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { PageHeader, CallToAction, CustomerQuote } from "../components/site/";

const LeaveRequests = (props) => (
  <Layout>
    <Seo
      title="Leave Requests | Compliant & Accurate Leave Calculations | PayHero"
      description="PayHero makes leave easy. Our leave requests feature provides employees and managers with all the information they need to request and approve leave online."
      pathname={props.location.pathname}
    />
    <PageHeader
      feature="Online Leave Management"
      title={[
        "The simplest way to ",
        <span key={0} className="-fontPrimary">
          manage leave
        </span>,
      ]}
      description="Manage all types of employee leave quickly and accurately and pay the right amount every time. Managing leave is now easier than ever, for everyone."
      imagePath="PageHeader_Leave.png"
    />
    <Container>
      <Wrapper>
        <Row stackGap={40} className="-textCenter">
          <Box size={33}>
            <h3>
              <IoIosInformationCircle />
              <br />
              All the details
            </h3>
            <p>
              Easy access to all the info you and your employees need to request
              and approve leave.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosGlobe />
              <br />
              Get it done online
            </h3>
            <p>
              Managing employee leave in PayHero ensures you’re compliant and
              leave is automatically included in your{" "}
              <Link to="/payroll">payroll</Link>.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosEye />
              <br />
              Don’t miss a thing
            </h3>
            <p>
              A calendar view of employee leave and Dashboard reminders of
              upcoming time off means you’ll always know where everyone is.
            </p>
          </Box>
        </Row>
        <CustomerQuote
          quote="The nightmare of public holiday calculations is not my problem anymore."
          name="Pippa Scott"
          company="The Gelato Lab"
          pic="GelatoLab_Circle.png"
          center
          large
        />
        <hr />
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="Employee Leave Requests | PayHero"
              filename="Portal_LeaveRequests.png"
            />
          </Box>
          <Box size={50}>
            <h2>Online leave requests</h2>
            <h4>
              Employees can log in and request leave online, including
              part-days. We’ll give them as much info as possible about their
              balances so they know exactly where they stand. The leave approver
              sees the same balances, and a list of any other staff taking leave
              at the same time.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50}>
            <h2>See employee leave like never before</h2>
            <h4>
              Keep across the details of your team’s time off with our beautiful
              online leave calendar. Never get caught out again – record,
              respond to and manage leave requests all from one place so you
              always know who’s away when.
            </h4>
          </Box>
          <Box size={50}>
            <Image
              alt="Request & Respond To Leave Requests With Ease | PayHero"
              filename="PayHero_LeaveDetail.png"
            />
          </Box>
        </Row>
        <CustomerQuote
          quote="My payroll takes me 10-15 minutes a week, and I’m able to answer all the questions from my team about their entitlements."
          name="Chris Kinnell"
          company="The Butcher’s Son"
          pic="TheButchersSon_Circle.png"
          large
          center
        />
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="Review Leave | PayHero"
              filename="LeaveUpcoming_Graphic_PayHero.png"
              addShadow
            />
          </Box>
          <Box size={50}>
            <h2>Everyone’s in the loop</h2>
            <h4>
              See new leave requests and upcoming approved leave on the PayHero
              dashboard. Once leave has been approved or declined, the employee
              will receive an email to let them know and they can see the status
              of each request when logged in.
            </h4>
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50}>
            <h2>
              Straight to your <Link to="/payroll">payroll</Link>
            </h2>
            <h4>
              When you run a pay, all approved employee leave for the period
              will be included. Leave entitlements are automatically calculated
              based on the employee setup or timesheet history.
            </h4>
          </Box>
          <Box size={50}>
            <Image
              alt="Leave, Time & Expenses All Sync with Payroll | PayHero"
              filename="PayHero_PayrollFlow.png"
            />
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default LeaveRequests;
